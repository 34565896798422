// import Image from 'next/image'

import { Container } from "../../components/Container";

const faqs = [
  [
    {
      question: "How do I open an account?",
      answer:
        "Our account registration process is as easy as clicking a 'Get Started' button anywhere on our website. They will all take you to the account registration page, or your dashboard if you have already logged in.",
    },
    {
      question: "How do I make a deposit?",
      answer:
        "Once logged into your account, you will find a 'deposit' panel as an option in the sidebar of your dashboard. This will enable you deposit really quickly.",
    },
  ],
  [
    {
      question: "What is copy trading?",
      answer:
        "Copy trading is most useful for those that do not wish to trade on their own or with an account manager. With copy trading, you can select from a list of specially selected traders and directly copy every trade they place.",
    },
    {
      question: "How does staking work?",
      answer:
        "Staking your crypto allows you to tap into the power of the blockchain decentralization and make profits. Your crypto is placed in a pool of traders and you earn on daily basis in the pool.",
    },
  ],
  [
    {
      question: "What trading options are available?",
      answer:
        "We offer a wide vareity of options spanning accross forex, including stocks and cryptocurrencies. We also offer practice accounts to demo your trades before taking on serious trades with a live one.",
    },
    // {
    //   question: "How do I invest in real estate?",
    //   answer:
    //     "We provide an out-of-the-box solution to earning from real estate. You can earn without even having to buy and keep property with the available options when you create an account.",
    // },
  ],
];

export function FAQHero() {
  return (
    <section
      id="faq"
      aria-labelledby="faq-title"
      className="relative overflow-hidden py-20 sm:py-32"
    >
      <Container className="relative">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="faq-title"
            className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl"
          >
            Frequently asked questions
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            If you can’t find what you’re looking for, use our live chat or
            email our support team and someone will get back to you.
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3"
        >
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="flex flex-col gap-y-8">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="font-display text-lg leading-7 text-slate-900">
                      {faq.question}
                    </h3>
                    <p className="mt-4 text-sm text-slate-700">{faq.answer}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
}
