import { useEffect } from "react";
import CTAFour from "../components/cta/CTAFour";
import CTAThree from "../components/cta/CTAThree";
import CTATwo from "../components/cta/CTATwo";
import ColoredCTA from "../components/cta/ColoredCTA";
import { CTAOne } from "../components/cta/CtaOne";
import DarkCTA from "../components/cta/DarkCTA";
import { FeaturesFour } from "../components/features/FeaturesFour";
import { FeaturesOne } from "../components/features/FeaturesOne";
import { FeaturesOneWithImage } from "../components/features/FeaturesOneWithImage";
import FeaturesThree from "../components/features/FeaturesThree";
import { FeaturesTwoWithImage } from "../components/features/FeaturesTwoWithImage";
import ColoredFooter from "../components/footer/ColoredFooter";
import FooterOne from "../components/footer/FooterOne";
import { Header } from "../components/header/Header";
import HeaderFive from "../components/header/HeaderFive";
import HeaderThree from "../components/header/HeaderThree";
import { Hero } from "../components/hero/Hero";
import HeroFive from "../components/hero/HeroFIve";
import HeroFour from "../components/hero/HeroFour";
import HeroThree from "../components/hero/HeroThree";
import HeroTwo from "../components/hero/HeroTwo";
import StatsOne from "../components/stats/StatsOne";
import StatsTwo from "../components/stats/StatsTwo";
import { TestimonialOne } from "../components/testimonials/TestimonialOne";
import { TestimonialTwo } from "../components/testimonials/TestimonialTwo";
import { TickerTape } from "react-ts-tradingview-widgets";
import { HeroSix } from "../components/hero/HeroSix";
import { CTAFive } from "../components/cta/CTAFive";

const Main = () => {
  useEffect(() => {
    function removeTickers() {
      const ticker = document.querySelectorAll("#tradingview_widget_wrapper");
      ticker?.forEach((tick) => {
        tick.lastChild.style.display = "none";
      });
    }

    removeTickers();

    return () => {
      removeTickers();
    };
  });

  const tickerSymbols = [
    {
      proName: "BITSTAMP:BTCUSD",
      title: "BTC/USD",
    },
    {
      proName: "BITSTAMP:ETHUSD",
      title: "ETH/USD",
    },
    {
      proName: "FOREXCOM:SPXUSD",
      title: "S&P 500",
    },
    {
      proName: "FOREXCOM:NSXUSD",
      title: "Nasdaq 100",
    },
    {
      proName: "FX_IDC:EURUSD",
      title: "EUR/USD",
    },
  ];

  return (
    <>
      {/* <div style={{ backgroundColor: "#0c0f19" }}></div> */}
      <Header />
      <HeroSix />

      <br />
      <br />
      <TickerTape colorTheme="light" symbols={tickerSymbols}></TickerTape>
      <FeaturesTwoWithImage />
      <div
        className="bg-blue-600"
        style={{
          // backgroundColor: "#0c0f19",
          padding: "12px 0px",
        }}
      >
        <StatsTwo />
      </div>
      <TestimonialTwo />
      {/* <TestimonialOne /> */}
      <CTAFive />
      <ColoredFooter />
      {/* <FooterOne /> */}
      {/* <FeaturesTwoWithImage /> */}
      {/* <HeroFive /> */}
      {/* <HeaderFive /> */}
      {/* <HeaderThree /> */}
      {/* <HeroFour /> */}
      {/* <HeroThree /> */}
      {/* <FeaturesTwoWithImage /> */}
      {/* <FeaturesThree /> */}
      {/* <StatsOne /> */}
      {/*  */}
      {/* <div style={{ backgroundColor: "#0c0f19" }}>
        <CTATwo />
      </div> */}
      {/* <CTATwo /> */}
      {/* <CTAOne /> */}
      {/* <CTAFour /> */}
      {/* <CTAThree /> */}
    </>
  );
};

export default Main;
