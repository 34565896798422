import WhiteMidCTA from "../components/cta/WhiteMidCTA";
import { FAQOne } from "../components/faq/FAQOne";
import { FAQTwo } from "../components/faq/FAQTwo";
import FooterOne from "../components/footer/FooterOne";
import { Header } from "../components/header/Header";
import { FAQHero } from "../components/hero/FAQHero";

import backgroundImage from "../images/background-faqs.jpg";

const FAQ = () => {
  return (
    <>
      {/* <img
        className="absolute left-1/2 top-0 max-w-none -translate-y-1/4 translate-x-[-30%]"
        src={backgroundImage}
        alt=""
        width={1558}
        height={"auto"}
        unoptimized
      /> */}
      {/* <div style={{ backgroundColor: "#0c0f19" }}> */}
      <Header />
      <FAQHero />
      {/* <FAQTwo /> */}
      {/* </div> */}
      <WhiteMidCTA />
      <FooterOne />
    </>
  );
};

export default FAQ;
