// import Image from 'next/image'

import { Container } from "../Container";
import avatarImage1 from "../../images/avatars/avatar-1.png";
import avatarImage2 from "../../images/avatars/avatar-2.png";
import avatarImage3 from "../../images/avatars/avatar-3.png";
import avatarImage4 from "../../images/avatars/avatar-4.png";
import avatarImage5 from "../../images/avatars/avatar-5.png";
import { siteSettings } from "../../settings";

const testimonials = [
  // reviews

  [
    {
      content:
        "Incredible company, I've been trading for a while with them, had two quick withdrawals and fee refund in less than 48 hours. I absolutely recommend the firm",
      author: {
        name: "Louis can Nuekerk",
        role: "Professional trader",
        image:
          "https://user-images.trustpilot.com/58c446dd0000ff000a808de2/73x73.png",
      },
    },
    {
      content:
        "This is an amazing platform which gives you opportunity to achieve their goals and become successful traders with the support of finances.",
      author: {
        name: "Adam",
        role: "User",
        image:
          " https://user-images.trustpilot.com/5cac659ab628b746a119fd61/73x73.png",
      },
    },
  ],
  [
    {
      content:
        "Great company, no issues with payouts, great customer support. Highly recommended for experienced traders.",
      author: {
        name: "Jakub Szulc",
        role: "Trader",
        image:
          "https://user-images.trustpilot.com/5fd721e2c4a9b800196081bd/73x73.png",
      },
    },
    {
      content:
        "legitimate! thank you for the opportunity, nothing out there can compare",
      author: {
        name: "Dee",
        role: "Review expert",
        image:
          "https://user-images.trustpilot.com/6321c934f2a8b90013faaf15/73x73.png",
      },
    },
  ],
  [
    {
      content: `${siteSettings.name} has everything you can ask for, I'm super happy it has been recommended to me as my first broker`,
      author: {
        name: "Agata Vincent",
        role: "Trader",
        image:
          "https://user-images.trustpilot.com/64a712e728582c0011a9260e/73x73.png",
      },
    },
    {
      content:
        "Amazing experience. I have a very nice experience using this platform. Execution speed is ont of the best all over the market.",
      author: {
        name: "Pro Kittisak",
        role: "User",
        image:
          "https://user-images.trustpilot.com/64c13a9a79f4110012303256/73x73.png",
      },
    },
  ],
];

function QuoteIcon(props) {
  return (
    <svg aria-hidden="true" width={105} height={78} {...props}>
      <path d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z" />
    </svg>
  );
}

export function TestimonialTwo() {
  return (
    <section
      id="testimonials"
      aria-label="What our customers are saying"
      className="bg-slate-50 py-20 sm:py-32"
    >
      <Container>
        <div className="mx-auto max-w-2xl md:text-center">
          <h2 className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">
            Built for today’s ambitious earners{" "}
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            Thousands of forward-thinking users rely on {siteSettings.name}{" "}
            everyday to turbo-charge their financial operations
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:gap-8 lg:mt-20 lg:max-w-none lg:grid-cols-3"
        >
          {testimonials.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="flex flex-col gap-y-6 sm:gap-y-8">
                {column.map((testimonial, testimonialIndex) => (
                  <li key={testimonialIndex}>
                    <figure className="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10">
                      <QuoteIcon className="absolute left-6 top-6 fill-slate-100" />
                      <blockquote className="relative">
                        <p className="text-lg tracking-tight text-slate-900">
                          {testimonial.content}
                        </p>
                      </blockquote>
                      <figcaption className="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6">
                        <div>
                          <div className="font-display text-base text-slate-900">
                            {testimonial.author.name}
                          </div>
                          <div className="mt-1 text-sm text-slate-500">
                            {testimonial.author.role}
                          </div>
                        </div>
                        <div className="overflow-hidden rounded-full bg-slate-50">
                          <img
                            className="h-14 w-14 object-cover"
                            src={testimonial.author.image}
                            alt=""
                            width={56}
                            height={56}
                          />
                        </div>
                      </figcaption>
                    </figure>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
}
